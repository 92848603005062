import React, { FC } from 'react'
import { StartupModel } from '../../../domain/startup.model'
import styles from './DisplayClientActivity.module.scss'
import { Col, Row } from 'antd'
import { getApiHost } from '../../../conf'

interface Props {
  startup: StartupModel
}

export const SmallStartupCard: FC<Props> = ({
  startup,
}: Props): JSX.Element => {
  return (
    startup != null && startup.id != null ?
      <>
        <Col
          key={startup.id}
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={8}
          className={styles.marginBottom}
        >
          <div className={styles.startupCard}>
            <Row gutter={20}>
              <Col xs={6} sm={4} md={6} lg={6}>
                <div className={styles.logoContainer}>
                  {startup.informations.logoId != null ? (
                    <img
                      className={styles.startupLogo}
                      alt={'Logo'}
                      src={`${getApiHost()}/asset-files/${startup.informations.logoId
                        }`}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
              <Col xs={18} sm={20} md={18} lg={18} className={styles.startupName}>
                {startup.informations.name}
              </Col>
            </Row>
          </div>
        </Col>
      </> : <></>
  )
}
