import { Button, Divider, Drawer, Form, Grid, Space } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import Section from '../../../components/Layout/Section'
import MainButton from '../../../components/MainButton/MainButton'
import RangeValueModel from '../../../components/Range/RangeValue.model'
import ApiContext from '../../../context/ApiContext'
import UserContext from '../../../context/UserContext'
import { ClientNeedHelper } from '../../../domain/client/client-needs.enum'
import { ClientModel } from '../../../domain/client/client.model'
import { StartupModel } from '../../../domain/startup.model'
import { hasPermissions } from '../../../services/hasPermission'
import usePermission from '../../../services/usePermission.hook'
import { Roles } from '../../../utils/roles.enum'
import useBreakpointValue from '../../../utils/useBreakpointValue.hook'
import { emptyFilter, Filter } from './StartupsFilter.model'
import styles from './StartupsFilter.module.scss'
import StartupsFilterForm from './StartupsFilterForm'
import StartupsFilterTags from './StartupsFilterTags'
import useStartupsFilter from './useStartupsFilter.hook'
import useStartupsFilterData from './useStartupsFilterData.hook'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import StartupStatusEnum from '../../../domain/startup-status.enum'
import CountryEnum from '../../../domain/country.enum'

interface Props {
  startups: StartupModel[]
  onFilter: (filteredStartups: StartupModel[]) => void
}

export default function StartupsFilter({
  startups = [],
  onFilter = () => {},
}: Props) {
  const { getMyClientProfil } = useContext(ApiContext)
  const { user } = useContext(UserContext)

  const [showFilter, setShowFilter] = useState(false)
  const [client, setClient] = useState<ClientModel>()
  const [filter, setFilter] = useState<Filter>(emptyFilter)

  const drawerWidth = useBreakpointValue<string>({
    xs: '100%',
    md: '80%',
    lg: '65%',
    xl: '50%',
  })
  const breakpoint = Grid.useBreakpoint()

  const filteredStartups = useStartupsFilter(startups, filter)
  const dataFilter = useStartupsFilterData(startups)

  // TODO move form instance inside StartupsFilterForm.tsx, should not be visible here
  const [form] = Form.useForm<Filter>()

  const isClient = usePermission(Roles.CLIENT)
  const { t } = useTranslation('startup')

  const removeFilterValue = (name: keyof Filter, value: any): void => {
    let values = filter[name]
    let update
    if (Array.isArray(values)) {
      // @ts-ignore
      update = { [name]: values.filter((i) => i !== value) }
    } else {
      update = { [name]: undefined }
    }
    form.setFieldsValue(update)
    setFilter({ ...filter, ...update })
  }

  const reset = (newFilter: Filter = emptyFilter): void => {
    form.setFieldsValue(newFilter)
    setFilter(newFilter)
  }

  const buildClientFilter = (data: ClientModel) => ({
    ...emptyFilter,
    sectors: data.sectors || [],
    regions: data.regions || [],
    fundRaiseNeeds: (data.need || []).map(ClientNeedHelper.toStartupNeed),
    fundRaiseStatus: data.raise || [],
    entryTicket: data.minInvest
      ? RangeValueModel.of(data.minInvest, data.maxInvest)
      : undefined,
  })

  useEffect(() => {
    let isMounted = true; // Flag to track if the component is mounted
    
    if (hasPermissions(user.roles, Roles.CLIENT)) {
      getMyClientProfil().then((data) => {
        if (isMounted) {
          setClient(data);
          reset(buildClientFilter(data));
        }
      });
    }
  
    if (hasPermissions(user.roles, Roles.INNO)) {
      reset({
        ...emptyFilter,
        statuses: [StartupStatusEnum.VALIDATED, StartupStatusEnum.TO_VALIDATE],
      });
    }
  
    return () => {
      isMounted = false; // Clean up by setting the flag to false on unmount
    };
  }, [user]);
  

  useEffect((): void => {
    onFilter(filteredStartups)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredStartups])

  return (
    <>
      {breakpoint.xs
        ? headerMobile(
            filteredStartups.length,
            filter,
            removeFilterValue,
            () => setShowFilter(true),
            t,
            isClient,
          )
        : headerDesktop(
            filteredStartups.length,
            filter,
            removeFilterValue,
            () => setShowFilter(true),
            t,
            isClient,
          )}
      <Drawer
        title={
          isClient
            ? t('refinedYouCriteria').toUpperCase()
            : t('refinedYourResearch').toUpperCase()
        }
        headerStyle={{ padding: '0px' }}
        width={drawerWidth}
        onClose={() => setShowFilter(false)}
        visible={showFilter}
        footer={
          <div className={styles.DrawerFooter}>
            <MainButton
              disabled={filteredStartups.length === 0}
              onClick={() => setShowFilter(false)}
            >
              {filteredStartups.length === 0
                ? t('noResult')
                : filteredStartups.length === 1
                  ? t('seeTheStartup')
                  : t('show') + ` ${filteredStartups.length} startups`}
            </MainButton>
            {client ? (
              <Button onClick={() => reset(buildClientFilter(client))}>
                {t('backToInitialCriteria')}
              </Button>
            ) : null}
            <Button onClick={() => reset()} type="link">
              {t('eraseCritera')}
            </Button>
          </div>
        }
      >
        <StartupsFilterForm
          form={form}
          filter={filter}
          dataFilter={dataFilter}
          onValuesChange={(values) =>
            setFilter({
              ...emptyFilter,
              ...values,
              regions:
                values.countries &&
                values.countries.includes(CountryEnum.FRANCE)
                  ? values.regions
                  : [],
            })
          }
        />
      </Drawer>
    </>
  )
}

const title = (count: number, t: TFunction, className: string) => {
  if (count === 0) {
    return (
      <h2 className={className}>
        {t('noStartup')}
        <small>
          {t('matching.not')} {t('matching.unique')}
        </small>
      </h2>
    )
  } else if (count === 1) {
    return (
      <h2 className={className}>
        1 {t('startup').toUpperCase()} <small>{t('matching.unique')}</small>
      </h2>
    )
  }
  return (
    <h2 className={className}>
      {count} {t('startups').toUpperCase()} <small>{t('matching.many')}</small>
    </h2>
  )
}

const headerDesktop = (
  count: number,
  filter: Filter,
  onTagRemove: (name: keyof Filter, value: any) => void,
  displayFilter: () => void,
  t: TFunction,
  isClient?: boolean,
) => (
  <div>
    <div className={styles.FilterBloc}>
      {title(count, t, isClient ? 'mt-40' : '')}
    </div>
    <div className={styles.FilterBloc}>
      <StartupsFilterTags filter={filter} onTagRemove={onTagRemove} />
      <MainButton className="flex-none" onClick={displayFilter}>
        {isClient ? t('refinedYouCriteria') : t('refinedYourResearch')}
      </MainButton>
    </div>
    <Divider />
  </div>
)

const headerMobile = (
  count: number,
  filter: Filter,
  onTagRemove: (name: keyof Filter, value: any) => void,
  displayFilter: () => void,
  t: TFunction,
  isClient?: boolean,
) => (
  <>
    <Section theme={'light'}>
      <Space direction="vertical">
        {title(count, t, isClient ? 'mt-40' : '')}
        <StartupsFilterTags filter={filter} onTagRemove={onTagRemove} />
      </Space>
    </Section>
    <Section theme={'light'}>
      <div className={styles.FilterBloc}>
        <Button type="link" onClick={displayFilter}>
          {t('refined')}
        </Button>
      </div>
    </Section>
  </>
)
